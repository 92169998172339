<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="512"
    height="512"
  >
    <path
      d="M16,20c-.212,0-.424-.068-.6-.2l-4-3c-.252-.188-.4-.485-.4-.8v-3.586L5.121,6.536c-.723-.724-1.121-1.685-1.121-2.707,0-2.111,1.718-3.829,3.828-3.829h12.344c2.11,0,3.828,1.717,3.828,3.829,0,1.022-.398,1.983-1.121,2.707l-5.879,5.879v6.586c0,.379-.214,.725-.553,.895-.142,.071-.295,.105-.447,.105Zm-5.8-1.6c-.751-.562-1.2-1.459-1.2-2.4v-2.757L3.707,7.95c-.951-.952-1.505-2.179-1.646-3.497-1.219,.641-2.061,1.905-2.061,3.376,0,1.022,.398,1.983,1.121,2.707l5.879,5.878v3.586c0,.315,.148,.611,.4,.8l4,3c.176,.132,.388,.2,.6,.2,.152,0,.306-.035,.447-.105,.339-.169,.553-.516,.553-.895v-2.5l-2.8-2.1Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'filtre'
};
</script>

<style lang="scss" scoped>
svg {
  fill: #704ad1;
  overflow: hidden;
  vertical-align: middle;
  transform: rotateZ(0deg);
  height: 25px;
  width: 25px;
}
</style>
